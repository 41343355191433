import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>Minute Fighter</p>
        <p>a competitive cooperative game of combat defined by 60 seconds.</p>
      </header>
      <p>a game by </p>
    </div>
  );
}

export default App;
